import { Button } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  MuiThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import React, { ReactElement } from "react";
import { ChessRoundData } from "../model/ChessRoundData";

interface Props {
  data: ChessRoundData;
  onSelect: (bucket: string, code: string) => any;
  onCancel?: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      "& > *": {
        margin: theme.spacing(1, 0),
      },
    },
    button: {
      minHeight: 60,
    },
  })
);

const chessTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

function ChessRound(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <MuiThemeProvider theme={chessTheme}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() =>
            props.onSelect(props.data.bucket, props.data.player2Code)
          }
        >
          {props.data.player2Name}
        </Button>
      </MuiThemeProvider>
      <Button
        className={classes.button}
        size="large"
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => props.onSelect(props.data.bucket, props.data.drawCode)}
      >
        Remi
      </Button>
      <MuiThemeProvider theme={chessTheme}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() =>
            props.onSelect(props.data.bucket, props.data.player1Code)
          }
        >
          {props.data.player1Name}
        </Button>
      </MuiThemeProvider>
      <Button size="small" color="default" fullWidth onClick={props.onCancel}>
        Avbryt
      </Button>
    </div>
  );
}

export default ChessRound;
