import { MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import "./index.css";
import { StoreState } from "./model/StoreState";
import { ChessActions, reducer } from "./reducer";
import reportWebVitals from "./reportWebVitals";

let middleware = [applyMiddleware(thunk)];

if (false) {
  middleware.push(
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
}

const store = createStore<StoreState, ChessActions, any, any>(
  reducer,
  undefined,
  compose(...middleware)
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#63982f",
    },
    secondary: {
      main: "#448ae1",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
