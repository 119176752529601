import queryString from "query-string";

export function parseUrl(raw: string): any | undefined {
  try {
    let url = new URL(raw);
    const query = queryString.parse(url.search);
    return [url.pathname, query];
  } catch (error) {
    console.error(error.message);
    return [undefined, undefined];
  }
}
