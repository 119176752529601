import { Button, Grid, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React, { ReactElement, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { setToken } from "../actions";
import { StoreState } from "../model/StoreState";
import { Api } from "../network/Api";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: { color: "grey", fontSize: 10 },
  })
);

function LoginStatus(props: Props & PropsFromRedux): ReactElement {
  const [logoutRequested, setLogoutRequested] = useState(false);
  let isLoggedIn = props.token != null;

  const classes = useStyles();

  const logout = () => {
    closeDialog();

    Api.clearToken();
    props.setToken(undefined);
    localStorage.removeItem("token");
  };

  const requestLogout = () => {
    setLogoutRequested(true);
  };

  const closeDialog = () => {
    setLogoutRequested(false);
  };

  return (
    <div>
      <Grid container direction="column" justify="center" alignItems="center">
        {isLoggedIn ? (
          <>
            <Grid item>
              <IconButton size="small" onClick={requestLogout}>
                <LockIcon style={{ color: "green" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <span className={classes.label}>LOGGA UT</span>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Link
                to="/login"
                component={() => (
                  <IconButton size="small">
                    <LockOpenIcon style={{ color: "red" }} />
                  </IconButton>
                )}
              />
            </Grid>
            <Grid item>
              <span className={classes.label}>LOGGA IN</span>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog open={logoutRequested} onClose={closeDialog}>
        <DialogTitle>Bekräfta utloggning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inga nya resultat kan rapporteras in förrän du loggar in på nytt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={closeDialog}
            color="primary"
            autoFocus
            size="small"
          >
            Stanna kvar
          </Button>
          <Button
            variant="contained"
            onClick={logout}
            color="default"
            size="small"
          >
            Logga ut
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: StoreState) => ({
  token: state.token,
});

const mapDispatchToProps = { setToken };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginStatus);
