import { Button, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setError } from "../actions";
import { login } from "../asyncActions";
import { StoreState } from "../model/StoreState";
import { ProcedureState } from "../ProcedureState";
import { parseUrl } from "../utils/Utils";
import StatusBox, { StatusBoxProps } from "./StatusBox";
import Scanner from "./Scanner";
import Stepper from "@material-ui/core/Stepper";
import { Step, StepLabel } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      "& > *": {
        margin: theme.spacing(1, 0),
      },
    },
  })
);

interface Props {}

function LoginView(
  props: Props & PropsFromRedux & RouteComponentProps
): ReactElement {
  const [manualMode, setManualMode] = useState(false);
  const [state, setState] = useState<ProcedureState>(ProcedureState.Unknown);
  const [token, setToken] = useState<string>("");
  const classes = useStyles();

  const { setError, login } = props;

  const onTokenChange = (e: any) => {
    setToken(e.target.value);
  };

  const tryLogin = useCallback(
    (token: string) => {
      setState(ProcedureState.InProgress);

      login(token)
        .then(() => setState(ProcedureState.Successful))
        .catch((error) => {
          setState(ProcedureState.Failed);
          setError("Inloggning misslyckades");
        })
        .finally(() => props.history.push("/login"));
    },
    [props.history, login, setError]
  );

  const processScan = useCallback(
    (value: string) => {
      const [path, obj] = parseUrl(value);
      if (obj?.token !== undefined) {
        const token: string = obj.token;
        setToken(token);
        tryLogin(token);
      } else {
        setError("Hittade ingen inloggning");
      }
    },
    [tryLogin, setError]
  );

  const queryInUrl = props.location.search.length > 0;

  useEffect(() => {
    if (queryInUrl) {
      processScan(window.location.href);
    }
  }, [processScan, queryInUrl]);

  const sb: StatusBoxProps | undefined = (function () {
    switch (state) {
      case ProcedureState.InProgress:
        return {
          state,
          label: "Loggar in",
        };
      case ProcedureState.Successful:
        return {
          state,
          label: "Inloggad",
          buttonText: "Fortsätt",
          buttonAction: () => props.history.push("/judge"),
        };
    }
  })();

  const getActiveStep = () => {
    if (token.length === 0) {
      return 0;
    } else {
      if (state !== ProcedureState.Successful) {
        return 1;
      } else {
        return 2;
      }
    }
  };

  return (
    <div>
      <Stepper activeStep={getActiveStep()}>
        <Step key={0}>
          <StepLabel>Logga in</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel error={state === ProcedureState.Failed}>
            Autentiserad
          </StepLabel>
        </Step>
      </Stepper>

      {state === ProcedureState.InProgress ||
      state === ProcedureState.Successful ? (
        <StatusBox {...sb!!} />
      ) : manualMode ? (
        <div className={classes.form}>
          <TextField
            fullWidth
            label="Autentiseringskod"
            onChange={onTokenChange}
            value={token}
            error={state === ProcedureState.Failed}
            helperText={
              state === ProcedureState.Failed
                ? "Felaktig autentiseringskod"
                : " "
            }
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => tryLogin(token)}
            disabled={token.length === 0}
          >
            Logga in
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setManualMode(false)}
          >
            Tillbaka
          </Button>
        </div>
      ) : (
        <div className={classes.form}>
          {!queryInUrl && (
            <Scanner
              dummyValue="https://schackfyran.app/login?token=WT9GRPIH8EXYI6YM"
              onScan={processScan}
            />
          )}
          <Button
            variant="contained"
            color="default"
            fullWidth
            onClick={() => setManualMode(true)}
          >
            Manuell inloggning
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: StoreState) => ({
  token: state.token,
});

const mapDispatchToProps = {
  login,
  setError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(LoginView));
