import { Dispatch } from "redux";
import { Api } from "./network/Api";
import { ChessActions } from "./reducer";
import * as actions from "./actions";

export function login(token: string) {
  return (dispatch: Dispatch<ChessActions>): Promise<any> => {
    return Api.login(token).then(() => {
      dispatch(actions.setToken(token));
      localStorage.setItem("token", token);
    });
  };
}
