import { Container, CssBaseline, Divider, Paper } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Route, Switch } from "react-router-dom";
import JudgeView from "./components/JudgeView";
import Layer10 from "./components/Layer10";
import LoginView from "./components/LoginView";
import TopBar from "./components/TopBar";
import { StoreState } from "./model/StoreState";

const useStyles = makeStyles<Theme, PropsFromRedux>((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.main,
    },
    main: {
      height: "100vh",
      padding: theme.spacing(2, 0),
    },
    inner: {
      padding: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2, 0, 1, 0),
    },
    snackbar: {
      bottom: theme.spacing(2),
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App(props: PropsFromRedux) {
  const classes = useStyles(props);
  const [hideError, setHideError] = React.useState(true);

  useEffect(() => {
    setHideError(props.error === undefined);
  }, [props.error]);

  const closeError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setHideError(true);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.main}>
          <Paper>
            <div className={classes.inner}>
              <TopBar />
              <Divider className={classes.divider} />
              <Switch>
                <Route path="(/|/judge)" component={JudgeView} />
                <Route path="/login" component={LoginView} />
              </Switch>
              <Layer10 />
            </div>
          </Paper>
        </div>
      </Container>
      <Snackbar
        open={!hideError && props.error !== undefined}
        autoHideDuration={5000}
        onClose={closeError}
        className={classes.snackbar}
      >
        <Alert onClose={closeError} severity="error">
          {props.error?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state: StoreState) => ({
  token: state.token,
  error: state.error,
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
