import { Grid, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { ReactElement } from "react";
import LoginStatus from "./LoginStatus";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: { color: "grey", fontSize: 10 },
  })
);

function TopBar(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <LoginStatus />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <IconButton size="small" onClick={() => window.location.reload()}>
                <RefreshIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <span className={classes.label}>LADDA OM</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default TopBar;
