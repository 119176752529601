import { Result } from "../model/Result";

export class Api {
  static token?: string;

  static readonly url = "https://api.schackfyran.layer10.se";
  static readonly timeout = 120000;

  private static getBaseUrl(): string {
    return Api.url;
  }

  private static async handleErrors(data: Response): Promise<Response> {
    if (!data.ok) {
      let errorBody = await data.json();
      console.error(errorBody);
      throw errorBody.message;
    }
    return data;
  }

  private static getAuthHeader(url: string): Record<string, string> {
    let authHeaders: Record<string, string> = {};
    if (this.token) {
      authHeaders.Authorization = "Bearer " + this.token;
    }
    return authHeaders;
  }

  private static async post(url: string, postData: any) {
    const controller = new AbortController();
    const timerId = setTimeout(() => controller.abort(), this.timeout);

    let response = await fetch(this.getBaseUrl() + url, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        ...Api.getAuthHeader(url),
      },
      signal: controller.signal,
    });

    clearTimeout(timerId);

    return (await this.handleErrors(response)).json();
  }

  static setToken(token: string) {
    this.token = token;
  }

  static clearToken() {
    this.token = undefined;
  }

  // ---------------------------------------------------------------------------
  // Login
  // ---------------------------------------------------------------------------

  static async login(token: string): Promise<any> {
    const controller = new AbortController();
    const timerId = setTimeout(() => controller.abort(), this.timeout);

    let response = await fetch(
      this.getBaseUrl() + "/login?required_role=JUDGE",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        signal: controller.signal,
      }
    );

    clearTimeout(timerId);

    await this.handleErrors(response);
    this.token = token;

    return Promise.resolve();
  }

  // ---------------------------------------------------------------------------
  // Results
  // ---------------------------------------------------------------------------

  static uploadResult(bucket: string, result: Result): Promise<Result> {
    return this.post(`/bucket/${bucket}/result`, result);
  }
}
