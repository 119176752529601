import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { ReactElement } from "react";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
    },
    text: { color: "grey", fontSize: 10, marginRight: 8 },
    logo: {
      height: 24,
    },
  })
);

interface Props {}

function Layer10(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item>
          <span className={classes.text}>UTVECKLAD AV</span>
        </Grid>
        <Grid item>
          <a href="https://www.layer10.se/" target="_blank" rel="noreferrer">
            <img src="l10.svg" alt="" className={classes.logo} />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default Layer10;
