import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setError } from "../actions";
import { ChessRoundData } from "../model/ChessRoundData";
import { StoreState } from "../model/StoreState";
import { Api } from "../network/Api";
import { ProcedureState } from "../ProcedureState";
import { parseUrl } from "../utils/Utils";
import ChessRound from "./ChessRound";
import StatusBox, { StatusBoxProps } from "./StatusBox";
import Scanner from "./Scanner";
import Stepper from "@material-ui/core/Stepper";
import { Step, StepLabel } from "@material-ui/core";

interface Props {}

function JudgeView(
  props: Props & PropsFromRedux & RouteComponentProps
): ReactElement {
  const [round, setRound] = useState<ChessRoundData | undefined>();
  const [successLabel, setSuccessLabel] = useState<string | undefined>(
    undefined
  );
  const [state, setState] = useState<ProcedureState>(ProcedureState.Unknown);

  const { setError } = props;

  const processScan = useCallback(
    (value: string) => {
      const [path, obj] = parseUrl(value);
      if (path !== undefined && obj !== undefined) {
        if (path === "/login") {
          setError("Du är redan inloggad");
          return;
        }

        const expectedKeys = ["bucket", "p1", "p2", "c1", "c2", "remi"];

        let keys = Object.keys(obj);
        const check1 =
          expectedKeys.filter((k) => keys.includes(k)).length ===
          expectedKeys.length;

        const check2 =
          Object.values(obj).filter((element) => {
            return (
              typeof element === "string" && (element as string).length > 0
            );
          }).length === expectedKeys.length;

        if (check1 && check2) {
          setRound({
            bucket: obj.bucket,
            player1Name: obj.p1,
            player2Name: obj.p2,
            player1Code: obj.c1,
            player2Code: obj.c2,
            drawCode: obj.remi,
          });
        } else {
          setError("Oläsbar kod");
        }
      }
    },
    [setError]
  );

  const queryInUrl = props.location.search.length > 0;

  useEffect(() => {
    if (props.token === undefined) {
      props.history.push("/login");
    } else if (queryInUrl) {
      processScan(window.location.href);
      props.history.push("/judge");
    }
  }, [processScan, props.history, props.token, queryInUrl]);

  const submitResult = (bucket: string, code: string) => {
    setState(ProcedureState.InProgress);

    Api.uploadResult(bucket, { code })
      .then(() => {
        if (code === round?.player1Code) {
          setSuccessLabel(round?.player1Name);
        } else if (code === round?.player2Code) {
          setSuccessLabel(round?.player2Name);
        } else {
          setSuccessLabel("Remi");
        }
        setState(ProcedureState.Successful);
      })
      .catch((error) => setState(ProcedureState.Failed));
  };

  const sb: StatusBoxProps | undefined = (function () {
    switch (state) {
      case ProcedureState.InProgress:
        return {
          state,
          label: "Skickar resultat",
        };
      case ProcedureState.Successful:
        return {
          state,
          label: successLabel,
          buttonText: "Fortsätt",
          buttonAction: () => {
            setRound(undefined);
            setState(ProcedureState.Unknown);
          },
        };
      case ProcedureState.Failed:
        return {
          state,
          label: "Ett fel inträffade",
          buttonText: "Försök igen",
          buttonAction: () => setState(ProcedureState.Unknown),
        };
    }
  })();

  const getActiveStep = () => {
    if (round === undefined) {
      return 0;
    } else {
      if (state !== ProcedureState.Successful) {
        return 1;
      } else {
        return 2;
      }
    }
  };

  return (
    <div>
      <Stepper activeStep={getActiveStep()}>
        <Step key={0}>
          <StepLabel>Scanna</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel error={state === ProcedureState.Failed}>Skicka</StepLabel>
        </Step>
      </Stepper>

      {state !== ProcedureState.Unknown ? (
        <StatusBox {...sb!!} />
      ) : round ? (
        <ChessRound
          data={round}
          onSelect={submitResult}
          onCancel={() => {
            setRound(undefined);
          }}
        />
      ) : (
        !queryInUrl && (
          <Scanner
            dummyValue="https://schackfyran.app/judge?bucket=ABC123&p1=Sven+Svensson&p2=Anders+Andersson&c1=JAIKEETH&c2=RAEGHAHD&remi=LIFOOCIE"
            onScan={processScan}
          />
        )
      )}
    </div>
  );
}

const mapStateToProps = (state: StoreState) => ({
  token: state.token,
});

const mapDispatchToProps = {
  setError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(JudgeView));
