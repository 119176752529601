import { StoreState } from "./model/StoreState";
import * as chessActions from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { Api } from "./network/Api";

export type ChessActions = ActionType<typeof chessActions>;

let token = localStorage.getItem("token");
if (token != null) {
  Api.setToken(token);
}

export const reducer = (
  state: StoreState = {
    token: token != null ? token : undefined,
  },
  action: ChessActions
) => {
  switch (action.type) {
    case getType(chessActions.setToken):
      return { ...state, token: action.payload };
    case getType(chessActions.setError):
      return { ...state, error: { message: action.payload } };
    case getType(chessActions.clearError):
      return { ...state, error: undefined };
    default:
      return state;
  }
};
