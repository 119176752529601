import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import React, { ReactElement } from "react";
import { ProcedureState } from "../ProcedureState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    doneIcon: {
      color: "green",
      marginRight: theme.spacing(1),
      width: 100,
      height: 100,
    },
    errorIcon: {
      color: "red",
      marginRight: theme.spacing(1),
      width: 100,
      height: 100,
    },
    box: {
      marginBottom: theme.spacing(2),
    },
  })
);

export interface StatusBoxProps {
  state: ProcedureState;
  label?: string;
  buttonText?: string;
  buttonAction?: () => any;
}

function StatusBox(props: StatusBoxProps): ReactElement {
  const classes = useStyles();

  const stateToIcon = () => {
    switch (props.state) {
      case ProcedureState.InProgress:
        return <CircularProgress size={100} />;
      case ProcedureState.Successful:
        return <CheckCircleIcon className={classes.doneIcon} />;
      case ProcedureState.Failed:
        return <ErrorIcon className={classes.errorIcon} />;
    }
  };
  return (
    <div>
      <div className={classes.box}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>{stateToIcon()}</Grid>
          <Grid item>
            <Typography align="center" variant="h6">
              {props.label}
            </Typography>
          </Grid>
        </Grid>
      </div>

      {props?.buttonText && props?.buttonAction && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={props?.buttonAction}
        >
          {props?.buttonText}
        </Button>
      )}
    </div>
  );
}

export default StatusBox;
