import { Button, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Alert from "@material-ui/lab/Alert";
import { Howl } from "howler";
import React, { ReactElement, useEffect, useState } from "react";
import QrReader from "react-qr-reader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activateButton: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  dummyValue: string;
  onScan?: (value: string) => any;
}

function Scanner(props: Props): ReactElement {
  const [sleeping, setSleeping] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  const classes = useStyles();

  const resetSleepTimer = () => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    let id = setTimeout(() => {
      setSleeping(true);
    }, 30000);

    setTimerId(id);
  };

  useEffect(() => {
    if (!sleeping) {
      resetSleepTimer();
    }
  }, [sleeping]);

  const handleScan = (data: string | null) => {
    if (data != null) {
      resetSleepTimer();

      playBeep();
      props.onScan?.(data);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const playBeep = () => {
    var sound = new Howl({
      src: ["sounds/beep.mp3"],
      volume: 0.1,
    });

    sound.play();
  };

  return sleeping ? (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <Alert icon={<BatteryAlertIcon />} severity="info">
          På grund av inaktivitet har skannern pausats för att spara batteri.
        </Alert>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSleeping(false)}
          startIcon={<PhotoCameraIcon />}
          className={classes.activateButton}
        >
          Aktivera
        </Button>
      </Grid>
    </Grid>
  ) : (
    <QrReader
      delay={300}
      onError={handleError}
      onScan={handleScan}
      style={{ width: "100%" }}
      facingMode="environment"
    />
  );
}

export default Scanner;
